.game h1 {
  font-size: 2.5em;
}

.points span {
  font-weight: bold;
}

.tip span {
  color: #ecfa00;
}

.wordContainer {
  margin: 1.5em;
  padding: 1.5em;
  border: 20px solid #ecfa00;
  display: flex;
}

.letter,
.blankSquare {
  font-size: 70px;
  line-height: 1.5;
  border: 3px solid #000;
  height: 100px;
  width: 100px;
  text-transform: uppercase;
  background-color: #fff;
  color: #000;
  font-weight: bold;
}

.letterContainer {
  margin-bottom: 1.2em;
}

.letterContainer form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.letterContainer input {
  height: 50px;
  width: 50px;
  font-size: 2em;
  text-align: center;
  margin-right: 1em;
}
